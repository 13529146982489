function verifyScroll(dropdownMenu) {
    if (!dropdownMenu) {
        return;
    }
    var scrollUpIndicator = dropdownMenu.find('.scroll-up-indicator') || dropdownMenu.prev('.scroll-up-indicator');
    var scrollDownIndicator = dropdownMenu.find('.scroll-down-indicator') || dropdownMenu.next('.scroll-down-indicator');

    if (dropdownMenu.scrollTop() > 0) {
        scrollUpIndicator.show();
    } else {
        scrollUpIndicator.hide();
    }

    if (dropdownMenu.scrollTop() < dropdownMenu.prop('scrollHeight') - dropdownMenu.innerHeight()) {
        scrollDownIndicator.show();
    } else {
        scrollDownIndicator.hide();
    }
}

function stickyHeader(){
    var header = $('.stickyHeader');
    var mobileHeader = $('.header.mainHeader');
    if ($(window).width() >= 768) {
        if($(this).scrollTop() > 38) {
            header.addClass('fixed');
        } else {
            header.removeClass('fixed');
        }
    } else {
        var headerHeight = mobileHeader.outerHeight();
        var searchContent = $('.search-mobile');
        if ($(this).scrollTop() > 38) {
            searchContent.css('padding-top', headerHeight + 'px');
            mobileHeader.addClass('fixed');
            $('.header.mainHeader .searchSuggestions__badge').removeClass('d-none')
        } else {
            mobileHeader.removeClass('fixed');
            searchContent.css('padding-top', '0');
            $('.header.mainHeader .searchSuggestions__badge').addClass('d-none')
        }
    }
}

function toggleSearch() {
    $('body').on('click', '.js-search-toggle-mobile', function (e) {
        e.preventDefault();

        var searchContent = $('.search-mobile');
        searchContent.css('position', 'fixed');
        searchContent.css('z-index', '1003');
        searchContent.css('padding-top', '12px');
        $('.searchSuggestions__bg').addClass('d-none')
        $('.search-modal-background').removeClass('d-none')
    });

    $('body').on('click', '.js-search-modal-background', function (e) {
        e.preventDefault();
        var mobileHeader = $('.header.mainHeader');
        var headerHeight = mobileHeader.outerHeight();

        var searchContent = $('.search-mobile');
        searchContent.css('position', '');
        searchContent.css('z-index', '');
        searchContent.css('padding-top', headerHeight + 'px');
        $('.search-modal-background').addClass('d-none')

        $('.searchBar').addClass('js-should-close');
    });
}

$(document).ready(function () {

    toggleSearch()

    $(window).on('scroll', function () {
        stickyHeader();

        if ($(this).scrollTop() <= 110) {
            if ($(window).width() >= 1200) {
                $('.user').css('display', '');
                $('.myList').css('display', '');
                $('.minicart').css('right', '0px');
                $('.site-search').width('30vw');
                $('.site-search').attr('style', 'right: 0px');
           }
        }
    });

    $(window).resize(function () {
        stickyHeader();
    });


    var currentSubmenu = null;
    var isMouseInSubmenu = false;
    var isMouseInDropdownItem = false;

    $('.dropdown-item.dropdown').each(function() {
        var dropdownItem = $(this);
        var ariaLabel = dropdownItem.attr('aria-label');
        var submenu = dropdownItem.nextAll('[aria-label="' + ariaLabel + '"]').first();
        var originalParent = submenu.parent();
    
        dropdownItem.mouseenter(function() {
            // Mouse enter
            isMouseInDropdownItem = true;
            if (currentSubmenu && currentSubmenu != submenu) {
                currentSubmenu.hide();
                currentSubmenu.appendTo(currentSubmenu.data('originalParent'));
            }

            // Show the new submenu
            if (submenu.length > 0) {
                var dropdownMenu = $(this).parents('.dropdown-menu');
                submenu.css({
                    'position': 'absolute',
                    'top': dropdownItem.offset().top,
                    'left': dropdownMenu.offset().left + dropdownMenu.outerWidth() - 3,
                    'height': 'fit-content',
                });
                submenu.appendTo('body');
                submenu.show();

                // Set the new submenu as the current submenu
                currentSubmenu = submenu;
            }
        });

        dropdownItem.mouseleave(function() {
            // Mouse leave
            isMouseInDropdownItem = false;
        });

        submenu.hover(function() {
            // Mouse enter
            isMouseInSubmenu = true;
            originalParent.addClass('show')
            originalParent.parent().addClass('show')
        }, function() {
            // Mouse leave
            isMouseInSubmenu = false;
            originalParent.removeClass('show')
            originalParent.parent().removeClass('show')
        });

        setInterval(function() {
            if (!isMouseInDropdownItem && !isMouseInSubmenu && currentSubmenu) {
                currentSubmenu.hide();
                currentSubmenu.appendTo(currentSubmenu.data('originalParent'));
                currentSubmenu = null;
            }
        }, 300);
    
        // Store the original parent for later use
        submenu.data('originalParent', originalParent);
    });

    var scrollInterval = null;
    var scrollAmount = 45; // Ajuste este valor para mudar a quantidade de rolagem

    $('.scroll-down-indicator').hover(function() {
        // Mouse enter
        var dropdownMenu = $(this).parents('.dropdown-menu');
        scrollInterval = setInterval(function() {
            dropdownMenu.scrollTop(dropdownMenu.scrollTop() + scrollAmount);
        }, 300);
    }, function() {
        // Mouse leave
        clearInterval(scrollInterval);
    });

    $('.scroll-up-indicator').hover(function() {
        // Mouse enter
        var dropdownMenu = $(this).parents('.dropdown-menu');
        scrollInterval = setInterval(function() {
            dropdownMenu.scrollTop(dropdownMenu.scrollTop() - scrollAmount);
        }, 300);
    }, function() {
        // Mouse leave
        clearInterval(scrollInterval);
    });


    $('.dropdown-menu').scroll(function () {
        verifyScroll($(this));
    });

    var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            if (mutation.attributeName === "class") {
                var dropdownMenu = $(mutation.target).find('.dropdown-menu').first();
                if ($(mutation.target).hasClass('dropdown-item')) {
                    var ariaLabel = $(mutation.target).attr('aria-label');
                    var submenu = $(mutation.target).nextAll('[aria-label="' + ariaLabel + '"]').first();
                    verifyScroll(submenu);
                } else if ($(mutation.target).hasClass('show')) {
                    verifyScroll(dropdownMenu);
                }
            }
        });
    });

    $('.dropdown').each(function() {
        observer.observe(this, {
            attributes: true
        });
    });
});